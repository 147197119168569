
import { Component, Prop, Vue } from 'vue-property-decorator';
import CaseImage from '@/components/Case/CaseImage.vue';
import CaseCountdown from '@/components/Index/CaseCard/CaseCountdown.vue';
import { FarmCaseAvailability } from '@/types/FreeCase';

@Component({
  components: { CaseImage, CaseCountdown },
})
export default class FreeCaseInfo extends Vue {
  @Prop({ required: true }) isFarmCase: boolean;
  @Prop({ required: true }) caseId: number;

  now: Date = new Date();

  updateNow() {
    this.now = new Date();
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  get caseAvailability(): FarmCaseAvailability {
    const freeCases = this.user?.farmCases ?? {};
    return (
      freeCases[this.caseId] ?? {
        conditionMet: false,
        nextOpenAt: null,
      }
    );
  }

  get nextOpenAt(): Date {
    return this.caseAvailability.nextOpenAt
      ? new Date(this.caseAvailability.nextOpenAt)
      : this.now;
  }

  get isPeriodMet() {
    return this.now >= this.nextOpenAt;
  }

  get isCaseAvailable(): boolean {
    return (
      !this.isFarmCase ||
      (this.isFarmCase &&
        this.caseAvailability.conditionMet &&
        this.isPeriodMet)
    );
  }
}
