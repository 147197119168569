
import { Component, Prop, Vue } from 'vue-property-decorator';

import { CaseCategory } from '@/types/Case';
import { getCaseValuablePrice } from '@/helpers';

import TextHeader from '@/components/Layout/TextHeader.vue';
import Case from '@/components/Index/CaseCard/CaseCard.vue';
import PromoBanner from '@/components/PromoBanner.vue';

const MANA_CATEGORY_ID = 12;
const PREMIUM_CATEGORY_ID = 17;
const BANNER_CATEGORY_ID = 15;
const FARM_CASE_CATEGORY_ID = 4;

@Component({
  components: { TextHeader, Case, PromoBanner },
})
export default class CasesPreview extends Vue {
  @Prop({ required: true }) categories: CaseCategory[];
  @Prop({ required: false }) forEvent: Boolean;

  isManaCase(categoryId: number) {
    return categoryId === MANA_CATEGORY_ID;
  }

  isPremiumCase(categoryId: number) {
    return categoryId === PREMIUM_CATEGORY_ID;
  }

  isBannerCategory(categoryId: number) {
    return categoryId === BANNER_CATEGORY_ID;
  }

  isBonusCase(caseId: number) {
    return !!this.bonusCaseIds.find(freeCaseId => freeCaseId === caseId);
  }

  isFarmCase(categoryId: number) {
    return FARM_CASE_CATEGORY_ID === categoryId;
  }

  getCaseValuablePrice(casePrice: number, categoryId: number) {
    return getCaseValuablePrice(casePrice, categoryId);
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  get bonusCaseIds(): number[] {
    return this.user
      ? this.$store.state.user.bonusCases.map(_case => _case.id)
      : [];
  }

  get bonusCases(): CaseCategory['cases'] {
    if (!this.user || this.forEvent == true) {
      return [];
    }

    return this.user.bonusCases.filter((_case, i, self) => {
      const caseIds: number[] = this.categories.flatMap(category =>
        category.cases.map(_c => _c.id)
      );
      return (
        self.findIndex(_c => _c.id === _case.id) === i &&
        caseIds.indexOf(_case.id) === -1
      );
    });
  }
}
