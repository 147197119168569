
import { Component, Vue } from 'vue-property-decorator';
import EventBanner from '@/components/Event/EventBanner.vue';
import UserTasks from '@/components/Event/UserTasks.vue';
import EventTop from '@/components/Event/Top.vue';
import WelcomeEvent from '@/components/Event/WelcomeEvent.vue';
import Countdown from '@/components/Event/Countdown.vue';
import CasesPreview from '@/components/Index/CasesPreview.vue';
import { GetEventTasksResponse } from '@/requests/Event/getTasks/getEventTasks';
import Button from '@/components/Buttons/Button.vue';
import ItemInfo from '@/components/Item/Item.vue';
import CompletedEvent from '@/components/Event/CompletedEvent.vue';
import EventTimer from '@/components/Event/EventTimer.vue';
import LoadingScreen from '@/components/Layout/LoadingScreen.vue';
import { getSeasonName } from '@/helpers/getSeasonName';
import PromoBanner from '@/components/PromoBanner.vue';

export const EVENT_END_TIME = new Date('2024-12-01T00:00:00');
const EVENT_START_TIME = new Date('2024-09-02T00:00:00');

@Component({
  components: {
    LoadingScreen,
    EventTimer,
    CompletedEvent,
    CasesPreview,
    EventBanner,
    UserTasks,
    EventTop,
    NoAuth: WelcomeEvent,
    Countdown,
    Button,
    ItemInfo,
    PromoBanner,
  },
})
export default class Event extends Vue {
  eventStarted: boolean = false;

  get user() {
    return this.$store.getters['user/isAuthorized']
        ? this.$store.state.user
        : null;
  }

  get eventEndTime() {
    return EVENT_END_TIME;
  }

  get season() {
    return this.$store.state.season;
  }

  get seasonName() {
    return getSeasonName(this.season);
  }

  created() {
    this.$setPageTitle('Приключение | DOTALOOT');

    if (
        localStorage.getItem('eventStarted') &&
        new Date(localStorage.getItem('eventStarted')) > EVENT_START_TIME
    ) {
      this.eventStarted = true;
    }
  }

  mounted() {
    this.$store.dispatch('caseCategories/fetch');
    this.$store.dispatch('event/fetchTasks');
    this.$store.dispatch('event/fetchTop', {filter: 'all'});

    if (!!window.location.hash) {
      setTimeout(function () {
        document
            .getElementById(window.location.hash.replace('#', ''))
            ?.scrollIntoView({behavior: 'smooth'});
        setTimeout(() => {
          window.scrollBy(0, -40);
        }, 650);
      }, 500);
    }
  }

  get tasksData(): GetEventTasksResponse {
    return this.$store.state.event.tasks.data;
  }

  get isTasksLoading() {
    return this.$store.state.event.tasks.isLoading;
  }

  get onlyFarmCaseCategories() {
    return this.$store.getters['caseCategories/farmCategories'];
  }

  get isDevelopment() {
    return process.env.NODE_ENV === 'development';
  }

  completeEvent() {
    this.$store.state.event.tasks.data.tasks.forEach(task => task.current = false);
  }

  startEvent() {
    this.eventStarted = true;
    localStorage.setItem('eventStarted', new Date().toString());
  }

  get userCompletedEvent() {
    if (this.user && !this.isTasksLoading) {
      return !this.tasksData.tasks.find(task => task.current);
    }

    return false;
  }
}
