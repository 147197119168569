
import { Component, Prop, Vue } from 'vue-property-decorator';

import { EventTask } from '@/types/Event';

import Button from '../../Buttons/Button.vue';
import ProgressBar from './ProgressBar.vue';

import { ruDeclension } from '@/helpers/ruDeclension';
import completeTask from '@/requests/Event/completeTask';
import CaseImage from '@/components/Case/CaseImage.vue';

@Component({
  components: { CaseImage, Button, ProgressBar },
})
export default class TaskInfo extends Vue {
  @Prop() task: EventTask;
  @Prop({ default: 0 }) taskIndex: number;
  subunitsCompleted: number = 0;

  mounted() {
    this.subunitsCompleted = this.getSubunitsCompleted();
  }

  get taskConditions() {
    return this.task.conditions;
  }

  get btnText() {
    switch (this.taskIndex) {
      case 2:
        return 'Открыть бесплатно';
      case 3:
        return 'Открыть за ману';
      default:
        return 'Перейти к выполнению';
    }
  }

  ruDec(val: number, strings: string[]) {
    return ruDeclension(val, strings);
  }

  async completeTask(id) {
    try {
      const { userMana } = await completeTask(id);
      this.$store.commit('user/update', { mana: userMana });
      this.$emit('taskCompleted');
    } catch (err) {
      console.error(err);
    }
  }

  proceedToTask(id, conditions: EventTask['conditions']) {
    if (conditions.changeNickname) {
      setTimeout(() => {
        this.completeTask(id);
      }, 7000);
      return window.open('https://store.steampowered.com/', '_blank').focus();
    }
    if (conditions.subscribeToSocials) {
      const temp = this.subunitsCompleted;
      this.subunitsCompleted += 1;

      setTimeout(() => {
        if (this.subunitsCompleted <= 2) {
          localStorage.setItem(
            'subscribeToSocialsCurrentStep',
            this.subunitsCompleted.toString()
          );
        } else {
          localStorage.removeItem('subscribeToSocialsCurrentStep');
          this.completeTask(id);
        }
      }, 7000);

      switch (temp) {
        case 0:
          return window.open('https://t.me/dotaloot', '_blank').focus();
        case 1:
          return window.open('https://vk.com/dotaloot', '_blank').focus();
        case 2:
          return window
            .open(
              'https://vk.com/app5898182_-184785421#s=2028433&force=1',
              '_blank'
            )
            .focus();
      }
    }
    if (
      conditions.refillBalance &&
      typeof conditions.refillBalance === 'number'
    ) {
      return this.$store.dispatch('modal/toggleModal', {
        name: 'refill',
        data: false,
      });
    }
    if (
      conditions.refillBalance &&
      typeof conditions.refillBalance === 'object'
    ) {
      return window.open('/promotions', '_blank').focus();
    }
    if (conditions.createContracts) {
      return window.open('/contract', '_blank').focus();
    }
    if (conditions.openCases && conditions.openCases[0].id) {
      return window
        .open(`/case/${conditions.openCases[0].id}`, '_blank')
        .focus();
    }
    if (conditions.createManaUpgrades || conditions.createUpgrades) {
      return window.open('/upgrade', '_blank').focus();
    }
    if (
      conditions.earnEventPoints &&
      typeof conditions.earnEventPoints === 'number'
    ) {
      return window.open('/#cases', '_blank').focus();
    }
  }

  getSubunitsCompleted() {
    if (this.task.conditions.subscribeToSocials) {
      let subscribeToSocialsCurrentStep: undefined | number | string =
        localStorage.getItem('subscribeToSocialsCurrentStep');
      subscribeToSocialsCurrentStep = subscribeToSocialsCurrentStep
        ? Number(subscribeToSocialsCurrentStep)
        : 0;
      return subscribeToSocialsCurrentStep;
    }

    return this.task.subunitsCompleted || 0;
  }
}
