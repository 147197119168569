var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"task"},[_c('CaseImage',{staticClass:"task__image",attrs:{"src":_vm.task.image,"alt":_vm.task.name}}),_c('div',{staticClass:"_content"},[_c('div',{staticClass:"task__name"},[_vm._v(" "+_vm._s(_vm.task.name)+" ")]),_c('div',{staticClass:"task__condition"},[(_vm.task.description)?_c('div',{staticClass:"task__desc"},[_vm._v(" "+_vm._s(_vm.task.description)+" ")]):_vm._e(),(_vm.taskConditions.changeNickname)?_c('div',[_vm._v(" Добавьте "),_c('span',{staticClass:"_accent"},[_vm._v("dotaloot.me")]),_vm._v(" в никнейм профиля Steam ")]):(_vm.taskConditions.earnEventPoints > 0)?_c('div',[_vm._v(" Накопите "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.earnEventPoints))]),_vm._v(" рун топа, открывая сундуки из линейки \"Самое Актуальное\" "),_c('ProgressBar',{attrs:{"state":[_vm.subunitsCompleted, _vm.taskConditions.earnEventPoints],"completed":!_vm.task.current}})],1):(_vm.taskConditions.refillBalance > 0)?_c('div',[_vm._v(" Пополните баланс от "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.refillBalance))]),_vm._v(" ₽ ")]):(
          typeof _vm.taskConditions.refillBalance === 'object' &&
          _vm.taskConditions.refillBalance.promotion
        )?_c('div',[_vm._v(" Используйте акцию "),_c('span',{staticClass:"_accent"},[_vm._v("«"+_vm._s(_vm.taskConditions.refillBalance.promotion)+"»")])]):(_vm.taskConditions.earnMana > 0)?_c('div',[_vm._v(" Накопите "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.earnMana))]),_vm._v(" маны ")]):(_vm.taskConditions.subscribeToSocials)?_c('div',{staticClass:"_subscribe"},[_c('div',{staticClass:"_head"},[_vm._v(" Подпишитесь на наши Telegram и VK "),_c('div',{staticClass:"_social-icons"},[_c('InlineSvg',{staticClass:"_tg",attrs:{"src":require('@/assets/img/icons/telegram.svg')}}),_c('InlineSvg',{staticClass:"_vk",attrs:{"src":require('@/assets/img/icons/vkontakte.svg')}}),_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/email.svg')}})],1)]),_c('ProgressBar',{attrs:{"completed":!_vm.task.current,"state":[_vm.subunitsCompleted, 3]}})],1):(
          _vm.taskConditions.openCases && _vm.taskConditions.openCases.length === 2
        )?_c('div',[(_vm.taskConditions.openCases[1] === 1)?_c('div',[_vm._v(" Откройте кейс "),_c('span',{staticClass:"_accent"},[_vm._v("«"+_vm._s(_vm.taskConditions.openCases[0].name)+"»")])]):(_vm.taskConditions.openCases[1] > 1)?_c('div',[_vm._v(" Откройте кейс "),_c('span',{staticClass:"_accent"},[_vm._v("«"+_vm._s(_vm.taskConditions.openCases[0].name)+"»")]),_vm._v(" "+_vm._s(_vm.taskConditions.openCases[1])+" "+_vm._s(_vm.ruDec(_vm.taskConditions.openCases[1], ["раз", "раза", "раз"]))+" "),_c('ProgressBar',{attrs:{"completed":!_vm.task.current,"state":[_vm.subunitsCompleted, _vm.taskConditions.openCases[1]]}})],1):_vm._e()]):(
          _vm.taskConditions.createContracts &&
          _vm.taskConditions.createContracts.length === 2
        )?_c('div',[(_vm.taskConditions.createContracts[1] === 1)?_c('div',[_vm._v(" Создайте контракт, от "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createContracts[0]))]),_vm._v(" ₽ ")]):_vm._e(),(_vm.taskConditions.createContracts[1] > 1)?_c('div',[_vm._v(" Создайте "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createContracts[1]))]),_vm._v(" "+_vm._s(_vm.ruDec(_vm.taskConditions.createContracts[1], [ "контракт", "контракта", "контрактов", ]))+", общая ценность каждого должна быть более "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createContracts[0]))]),_vm._v(" ₽ "),_c('ProgressBar',{attrs:{"state":[_vm.subunitsCompleted, _vm.taskConditions.createContracts[1]],"completed":!_vm.task.current}})],1):_vm._e()]):(
          _vm.taskConditions.createUpgrades &&
          _vm.taskConditions.createUpgrades.length === 2
        )?_c('div',[(_vm.taskConditions.createUpgrades[1] === 1)?_c('div',[_vm._v(" Создайте апгрейд, от "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createUpgrades[0]))]),_vm._v(" ₽ ")]):_vm._e(),(_vm.taskConditions.createUpgrades[1] > 1)?_c('div',[_vm._v(" Создайте "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createUpgrades[1]))]),_vm._v(" "+_vm._s(_vm.ruDec(_vm.taskConditions.createUpgrades[1], [ "апгрейд", "апгрейда", "апгрейдов", ]))+", общая ценность каждого должна быть более "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createUpgrades[0]))]),_vm._v(" ₽ "),_c('ProgressBar',{attrs:{"state":[_vm.subunitsCompleted, _vm.taskConditions.createUpgrades[1]],"completed":!_vm.task.current}})],1):_vm._e()]):(
          _vm.taskConditions.createManaUpgrades &&
          _vm.taskConditions.createManaUpgrades.length === 2
        )?_c('div',[(_vm.taskConditions.createManaUpgrades[1] === 1)?_c('div',[_vm._v(" Создайте апгрейд, от "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createManaUpgrades[0]))]),_vm._v(" маны ")]):_vm._e(),(_vm.taskConditions.createManaUpgrades[1] > 1)?_c('div',[_vm._v(" Создайте "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createManaUpgrades[1]))]),_vm._v(" "+_vm._s(_vm.ruDec(_vm.taskConditions.createManaUpgrades[1], [ "апгрейд", "апгрейда", "апгрейдов", ]))+", общая ценность каждого должна быть более "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createManaUpgrades[0]))]),_vm._v(" маны "),_c('ProgressBar',{attrs:{"state":[_vm.subunitsCompleted, _vm.taskConditions.createManaUpgrades[1]],"completed":!_vm.task.current}})],1):_vm._e()]):(
          _vm.taskConditions.createManaUpgrades &&
          _vm.taskConditions.createManaUpgrades.length === 2
        )?_c('div',[(_vm.taskConditions.createManaUpgrades[1] === 1)?_c('div',[_vm._v(" Создайте апгрейд, от "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createManaUpgrades[0]))]),_vm._v(" маны ")]):_vm._e(),(_vm.taskConditions.createManaUpgrades[1] > 1)?_c('div',[_vm._v(" Создайте "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createManaUpgrades[1]))]),_vm._v(" "+_vm._s(_vm.ruDec(_vm.taskConditions.createManaUpgrades[1], [ "апгрейд", "апгрейда", "апгрейдов", ]))+", общая ценность каждого должна быть более "),_c('span',{staticClass:"_accent"},[_vm._v(_vm._s(_vm.taskConditions.createManaUpgrades[0]))]),_vm._v(" маны "),_c('ProgressBar',{attrs:{"state":[_vm.subunitsCompleted, _vm.taskConditions.createManaUpgrades[1]],"completed":!_vm.task.current}})],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"task__action"},[(_vm.task.current)?_c('Button',{attrs:{"type":"green","text":_vm.btnText},nativeOn:{"click":function($event){return _vm.proceedToTask(_vm.task.id, _vm.task.conditions)}}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }