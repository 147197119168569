
import { Component, Prop, Vue } from 'vue-property-decorator';

import Button from '@/components/Buttons/Button.vue';
import AuthButton from '@/components/Buttons/AuthButton.vue';
import LoginGeneralButton from '@/components/Buttons/LoginGeneralButton.vue';
import Countdown from '@/components/Event/Countdown.vue';
import ItemInfo from '@/components/Item/Item.vue';
import { GetEventTopResponse } from '@/requests/Event/getTop/getEventTop';
import EventTimer from '@/components/Event/EventTimer.vue';
import { ruDeclension } from '@/helpers';
import Avatar from '@/components/Avatar.vue';
import { getSeasonName } from '@/helpers/getSeasonName';

@Component({
  components: {
    Avatar,
    EventTimer,
    ItemInfo,
    Countdown,
    Button,
    AuthButton,
    LoginGeneralButton,
  },
})
export default class CompletedEvent extends Vue {
  @Prop(Date) readonly eventEndTime: Date;

  get season() {
    return this.$store.state.season;
  }

  get seasonName() {
    return getSeasonName(this.season);
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  get firstTopPlaceItem() {
    return this.topData.prizeItems[0] ?? null;
  }

  get topData(): GetEventTopResponse {
    return this.$store.state.event.top.all.data;
  }

  get runesTillFirstPlace() {
    if (this.$store.state.event.top.all.isLoading) return 0;
    if (this.topData.userPlace === 1) return 0;

    return Math.round(
      this.topData.players[0].eventScore - this.topData.userScore + 1
    );
  }

  ruDec(val: number, strings: string[]) {
    return ruDeclension(val, strings);
  }
}
