var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"event-top"}},[_c('div',{staticClass:"event-top-players-wrap"},[_c('div',{class:{
        'event-top-players-intro': true,
        '--logged-in': _vm.user,
        '--no-auth': !_vm.user,
      }},[_c('div',{staticClass:"_left-col"},[_c('div',[_c('TextHeader',{staticClass:"event-top-players-title",attrs:{"text":"Таблица лидеров","level":2}}),(!_vm.user)?_c('LoginGeneralButton'):_vm._e()],1),_c('Switcher',{attrs:{"items":[
            { code: 'day', name: 'За сутки' },
            { code: 'all', name: 'Все приключение' },
          ],"active":_vm.filter},on:{"onChange":type => (_vm.filter = type)}}),(_vm.user)?_c('TopPlace',{key:'me-' + _vm.filter,attrs:{"user":_vm.userForTop,"place":_vm.userPlace,"item":_vm.userPlace <= 10 ? _vm.prizeItems[_vm.userPlace - 1] : null,"me":true}}):_vm._e()],1),_c('div',{staticClass:"_right-col"},[_c('div',{class:{
            'event-top-players-intro__title': true,
            'event-top-players-main-title': true,
            '--logged-in': _vm.user,
          }},[_vm._m(0),_vm._m(1)]),_vm._m(2),_c('div',{staticClass:"event-top-players-date-filter"},[_c('div',{staticClass:"event-top-players-timer"},[_vm._m(3),_c('div',{staticClass:"event-top-players-timer__time"},[_c('Countdown',{attrs:{"date":_vm.filter === 'all' ? _vm.eventEndTime : _vm.dailyEventEndTime,"short":"","onlyTime":_vm.filter !== 'all'}})],1)]),(false)?_c('DateChanger',{on:{"onChange":day => (_vm.selectedDay = day)}}):_vm._e()],1)])]),_c('div',{staticClass:"event-top-players-filters-title-mob"},[_c('TextHeader',{attrs:{"level":2,"text":'Таблица лидеров',"division":true,"id":''}})],1),_c('div',{staticClass:"event-top-players-filters-mob"},[_c('div',[_c('Switcher',{attrs:{"items":[
            { code: 'day', name: 'За сутки' },
            { code: 'all', name: 'Все приключение' },
          ],"active":_vm.filter},on:{"onChange":type => (_vm.filter = type)}})],1),_c('div',{staticClass:"event-top-players-date-filter event-top-players-filters-mob__right"},[_c('div',{staticClass:"event-top-players-timer"},[_vm._m(4),_c('div',{staticClass:"event-top-players-timer__time"},[_c('Countdown',{attrs:{"date":_vm.filter === 'all' ? _vm.eventEndTime : _vm.dailyEventEndTime,"short":"","onlyTime":_vm.filter !== 'all'}})],1)]),(false)?_c('DateChanger',{on:{"onChange":day => (_vm.selectedDay = day)}}):_vm._e()],1)]),(_vm.user)?_c('TopPlace',{key:'me2-' + _vm.filter,staticClass:"_only-mob",attrs:{"user":_vm.userForTop,"place":_vm.userPlace,"item":_vm.userPlace <= 10 ? _vm.prizeItems[_vm.userPlace - 1] : null,"me":true}}):_vm._e(),_c('div',{staticClass:"event-top-players-bottom"},[_c('div',_vm._l((_vm.players.slice(
            0,
            Math.ceil(_vm.players.length / 2)
          )),function(player,index){return _c('TopPlace',{key:index + '_topplayer' + player?.uuid + _vm.filter,attrs:{"user":player,"place":index + 1,"item":_vm.prizeItems[index]}})}),1),_c('div',_vm._l((_vm.players.slice(
            Math.ceil(_vm.players.length / 2)
          )),function(player,index){return _c('TopPlace',{key:index + '_2topplayer' + player?.uuid + _vm.filter,attrs:{"user":player,"place":index + Math.ceil(_vm.players.length / 2) + 1,"item":_vm.prizeItems[index + Math.ceil(_vm.players.length / 2)]}})}),1)]),_c('div',{staticClass:"event-top-players-update-info"},[_vm._v(" Таблица лидеров обновляется раз в 5 минут ")])],1),_c('div',{staticClass:"change-date-for-mob"},[(false)?_c('DateChanger',{on:{"onChange":day => (_vm.selectedDay = day)}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"_mob-titles"},[_c('div',{},[_vm._v("собирай РУНЫ и забирай")]),_c('div',{staticClass:"_second"},[_vm._v("ЦЕННЫЕ призы!")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"_row-1"},[_c('h3',[_vm._v("Как получить Руны?")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"event-top-players-intro__text"},[_c('div',[_c('p',[_vm._v(" Открывай "),_c('strong',[_vm._v("сезонные сундуки")]),_c('br'),_vm._v(" Выполняй задания из "),_c('strong',[_vm._v("приключения")]),_c('br'),_vm._v(" Поднимайся выше по таблице лидеров"),_c('br'),_vm._v(" Топ 10 игроков ивента получат "),_c('strong',[_vm._v("особые предметы")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"event-top-players-timer__label"},[_c('span',[_vm._v(" Раздача через ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"event-top-players-timer__label"},[_c('span',[_vm._v(" Раздача через ")])])
}]

export { render, staticRenderFns }