
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop({ type: String, required: false }) readonly text?: string;
  @Prop({ type: String, required: false }) readonly icon?: string;
  @Prop(String) readonly type: string;
  @Prop(String) readonly size: string;
}
