
import { Component, Prop, Vue } from 'vue-property-decorator';

import Button from '@/components/Buttons/Button.vue';

interface bannerType {
  code: string;
  title: string;
  text: string;
  percent: string;
  img: string;
}

const defaultTitle: string = 'Ты нашел секретный промокод на ';
const bannerTypes: bannerType[] = [
  {
    code: 'NaFontan',
    title: defaultTitle,
    text: 'Его можно использовать лишь раз!',
    percent: '21',
    img: 'promo-banner-1.png',
  },
  {
    code: 'Glimpse322',
    title: defaultTitle,
    text: 'Он работает только при пополнении от 3322 руб',
    percent: '25',
    img: 'promo-banner-2.png',
  },
  {
    code: 'Bottle',
    title: defaultTitle,
    text: 'У него всего три заряда, работает при пополнении от 675 руб',
    percent: '20',
    img: 'promo-banner-3.png',
  },
  {
    code: 'Necronomicon',
    title: defaultTitle,
    text: 'Используй скорее, а то вдруг удалят!',
    percent: '17',
    img: 'promo-banner-4.png',
  },
  {
    code: '',
    title: 'Больше бесплатных предметов!',
    text: 'Участвуй в розыгрышах, читай новости и получай свежие промокоды в наших социальных сетях!',
    percent: '',
    img: 'promo-banner-social.png',
  },
  {
    code: '',
    title: 'Невероятно, Рубик захватил ЛАВКУ!',
    text: 'Жми на зеленую кнопку и разберись уже с этим безумцем!',
    percent: '',
    img: 'promo-banner-6.png',
  },
  {
    code: '',
    title: 'РАДЫ ТЕБЯ ПРИВЕТСТВОВАТЬ, путник!',
    text:
      'Отправляйся во временное <span class="_blue">Весеннее Приключение</span>, проходи <span class="_green">простые задания</span> и получай <span class="_green">случайную Аркану</span> за каждые 15 уровней!' +
      '<br />А за полное прохождение <span class="_orange">гарантированный сундук с очень ценными лутом</span>, <span class="_blue">44 бесплатных предмета</span>, <span class="_orange">10000 рун</span>' +
      ' и еще очень много <span class="_blue">маны</span>..',
    percent: '',
    img: 'promo-banner-7.png',
  },
  {
    code: 'MuteReport',
    title: 'ЛОВИ секретный промокод на',
    text: 'У него всего три заряда, работает при пополнении от 300 руб',
    percent: '17',
    img: 'promo-banner-404.png',
  },
];

@Component({
  components: { Button },
})
export default class PromoBanner extends Vue {
  @Prop({ type: Number, required: true }) readonly type: number;

  get bannerParams() {
    return bannerTypes[this.type - 1];
  }

  copyCode(code: string) {
    navigator.clipboard.writeText(code);
    this.$notify({
      group: 'notification',
      duration: 5500,
      type: 'success',
      title: `Промокод "${code}"`,
      text: 'Код скопирован в буфер обмена!',
    });
  }
}
