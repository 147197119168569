
import { Component, Prop, Vue } from 'vue-property-decorator';
import VLazyImage from 'v-lazy-image/v2';

import { getCaseImage } from '@/helpers';

@Component({ components: { VLazyImage } })
export default class ItemImage extends Vue {
  @Prop() readonly src: string;
  @Prop() readonly alt: string;
  @Prop() readonly mana?: boolean;

  get caseImage() {
    if (!this.src) return require('@/assets/img/case-placeholder.svg');
    return getCaseImage(this.src, this.mana);
  }
}
