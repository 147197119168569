
import { Component, Vue, Watch } from 'vue-property-decorator';

import ItemInfo from '@/components/Item/Item.vue';
import TopPlace from '@/components/Event/TopPlace.vue';
import TextHeader from '@/components/Layout/TextHeader.vue';
import Switcher from '@/components/Switcher.vue';
import LoginGeneralButton from '@/components/Buttons/LoginGeneralButton.vue';
import DateChanger from '@/components/DateChanger.vue';
import Countdown from '@/components/Event/Countdown.vue';
import { EVENT_END_TIME } from '@/pages/Event.vue';

import {
  EventTopFilter,
  GetEventTopResponse,
} from '@/requests/Event/getTop/getEventTop';

import { endOfDay, endOfToday, getDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  components: {
    TextHeader,
    ItemInfo,
    Switcher,
    DateChanger,
    Countdown,
    TopPlace,
    LoginGeneralButton,
  },
})
export default class EventTop extends Vue {
  filter: EventTopFilter = 'day';
  uniqueKey: string = '1';
  selectedDay: string = '';

  mounted() {
    this.$store.dispatch('event/fetchTop', { filter: this.filter });
  }

  get dailyEventEndTime() {
    const dayNow = getDay(new Date());
    const dayMoscow = getDay(utcToZonedTime(endOfToday(), 'Europe/Moscow'));
    return dayNow != dayMoscow
      ? endOfDay(utcToZonedTime(endOfToday(), 'Europe/Moscow'))
      : endOfToday();
  }

  get players(): GetEventTopResponse['players'] {
    return this.$store.state.event.top[this.filter].data.players;
  }

  get prizeItems(): GetEventTopResponse['prizeItems'] {
    return this.$store.state.event.top[this.filter].data.prizeItems;
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  get userPlace() {
    return this.$store.state.event.top[this.filter].data.userPlace;
  }

  get userWins() {
    return this.$store.state.event.top[this.filter].data.userWins;
  }

  get userScore() {
    return this.$store.state.event.top[this.filter].data.userScore;
  }

  get eventEndTime() {
    return EVENT_END_TIME;
  }

  get userForTop() {
    if (this.user) {
      const minRank = Math.min(15, this.players.length);
      const tillLevel = this.userPlace > minRank ? minRank : this.userPlace - 1;
      const needScore =
        this.players[tillLevel - 1]?.eventScore - (this.userScore ?? 0);
      return {
        ...this.user,
        eventScore: this.userScore,
        wins: this.userWins,
        needScore,
        tillLevel,
      };
    }

    return null;
  }

  @Watch('filter')
  onFilterChange() {
    this.$store.dispatch('event/fetchTop', {
      filter: this.filter,
      refetch: true,
    });
  }

  @Watch('selectedDay')
  onDayChange() {}
}
