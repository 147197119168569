
import { Component, Prop, Vue } from 'vue-property-decorator';
import ItemInfo from '@/components/Item/Item.vue';
import { Item } from '@/types/Item';

@Component({
  components: { ItemInfo },
})
export default class EventBanner extends Vue {
  get items(): Item[] {
    return [
      {
        id: 1,
        name: 'Каждые три часа',
        image: require('@/assets/img/event/banner-item-1.png'),
        type: 'money',
        color: 'FF8B00',
        price: 0,
      },
      {
        id: 1,
        name: 'Каждый день',
        image: require('@/assets/img/event/banner-item-2.png'),
        type: 'money',
        color: '49B32C',
        price: 0,
      },
      {
        id: 1,
        name: 'Каждый ивент',
        image: require('@/assets/img/event/banner-item-3.png'),
        type: 'money',
        color: 'FF8B00',
        price: 0,
      },
    ];
  }

  get randomArcane(): Item {
    const items = [...this.rewardItems()];
    items.shift();

    const randomIdx = Math.floor(Math.random() * items.length);

    return items[randomIdx];
  }
  rewardItems(): Item[] {
    return this.$store.state.event.tasks.data.rewardItems;
  }
}
