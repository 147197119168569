
import { Component, Prop, Vue } from 'vue-property-decorator';
import { format, addDays, isToday } from 'date-fns';

@Component
export default class DateChanger extends Vue {

  currentDate: Date = new Date();
  
  mounted() {
  }

  formatDate(f: string) {
    return format(this.currentDate, f);
  }

  get maxDate() {
    return isToday(this.currentDate);
  }

  decDate() {
    this.currentDate = addDays(this.currentDate, -1);
    // this.$emit('onChange', this.formatDate('dd-MM-yyyy'));
  }

  incDate() {
    if (!this.maxDate) {
      this.currentDate = addDays(this.currentDate, 1);
      // this.$emit('onChange', this.formatDate('dd-MM-yyyy'));
    }
  }
}
