
import { Component, Vue } from 'vue-property-decorator';
import { UserAuthProvider } from '@/types/User';

@Component
export default class LoginGeneralButton extends Vue {
  auth(provider: UserAuthProvider) {
    window.location.href = `/auth/${provider}`;
  }
}
