
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ruDeclension } from '@/helpers/ruDeclension';
import { zonedTimeToUtc } from 'date-fns-tz';

@Component
export default class Countdown extends Vue {
  @Prop(Date) readonly date: Date;
  @Prop({ type: Boolean, default: false }) short: boolean;
  @Prop({ type: Boolean, default: false }) onlyTime: boolean;
  @Prop({ type: Boolean, default: false }) inCase: boolean;

  now: number = Math.trunc(new Date().getTime() / 1000);
  isFinished: Boolean = false;
  event: Date;

  mounted() {
    const _self = this;
    this.event = this.date;
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
      if (!this.isFinished && this.calculatedDate - this.now <= 0) {
        _self.isFinished = true;
        _self.$emit('onFinish');
      }
    }, 1000);
  }

  get calculatedDate() {
    return Math.trunc(
      Date.parse(zonedTimeToUtc(this.date, 'Europe/Moscow').toString()) / 1000
    );
  }

  get secondCount() {
    return this.calculatedDate - this.now;
  }

  get seconds() {
    if (this.secondCount < 0) return 0;
    return this.secondCount % 60;
  }

  get minutes() {
    if (this.secondCount < 0) return 0;
    return Math.trunc(this.secondCount / 60) % 60;
  }

  get hours() {
    if (this.secondCount < 0) return 0;
    return Math.trunc(this.secondCount / 60 / 60) % 24;
  }

  get days() {
    if (this.secondCount < 0) return 0;
    return Math.trunc(this.secondCount / 60 / 60 / 24);
  }

  twoDigits(val: number) {
    return val.toString().length <= 1 ? '0' + val.toString() : val.toString();
  }

  ruDec(val: number, strings: string[]) {
    return ruDeclension(val, strings);
  }
}
