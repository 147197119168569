
import { Component, Prop, Vue } from 'vue-property-decorator';
import Countdown from '@/components/Event/Countdown.vue';

@Component({
  components: { Countdown },
})
export default class EventTimer extends Vue {
  @Prop(Date) readonly eventEndTime: Date;
}
