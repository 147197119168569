var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'item-info',
    'item-info--price-' + (_vm.itemData?.type === 'rune' ? 'hidden' : _vm.priceMode),
    'item-info--' + (_vm.enableShadow ? 'just-shadow' : ''),
    'item-info--hover-' + _vm.hoverMode,
    _vm.isHoverAllowed ? 'item-info--allow-hover' : '',
    !_vm.itemData ? 'item-info--empty' : '',
  ],on:{"mouseover":function($event){$event.preventDefault();_vm.isHovered = true},"mouseleave":function($event){$event.preventDefault();_vm.isHovered = false},"touchstart":function($event){_vm.isHovered = true}}},[_c('ItemImage',{staticClass:"item-info__image",attrs:{"item":_vm.itemData}}),(!_vm.hideTitle || _vm.enableShadow)?_c('div',{staticClass:"item-info__shadow",style:('box-shadow: ' + _vm.shadowStyle)}):_vm._e(),(_vm.itemData?.type !== 'rune' && _vm.priceMode !== 'hidden')?_c('div',{staticClass:"item-info__price"},[_vm._v(" "+_vm._s(_vm.itemData?.price ?? "-")+" "),_c('img',{staticClass:"_steam",attrs:{"src":require('@/assets/img/icons/steam.svg'),"height":"10"}})]):_vm._e(),_vm._t("topRight"),(!_vm.hideTitle)?_c('div',{staticClass:"item-info__name",style:({ backgroundColor: '#' + _vm.getColor(_vm.itemData?.color) })},[_vm._v(" "+_vm._s(_vm.itemData?.name ?? "??????")+" ")]):_vm._e(),_vm._t("after")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }