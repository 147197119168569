
import { Component, Prop, Vue } from 'vue-property-decorator';

import { EventTask } from '@/types/Event';
import { Item } from '@/types/Item';
import CaseImage from '@/components/Case/CaseImage.vue';
import ItemInfo from '@/components/Item/Item.vue';
import { ruDeclension } from '@/helpers/ruDeclension';
import VLazyImage from 'v-lazy-image/v2';

const ACCESS_CASE_ID = [72, 74, 75, 59, 61];
@Component({
  components: { CaseImage, ItemInfo, VLazyImage },
})
export default class TaskReward extends Vue {
  @Prop() taskRewards: EventTask['rewards'];
  @Prop() lvlUntilArcane: number;
  @Prop() lvlUntilHook: number;
  @Prop() rewardItems: Item[];
  @Prop() currentTaskIndex: number;

  get randomArcane(): Item {
    const items = [...this.rewardItems];
    items.shift();

    const randomIdx = Math.floor(Math.random() * items.length);

    return items[randomIdx];
  }

  get shouldHideRunes() {
    return [0, 2, 3].includes(this.currentTaskIndex)
  }

  caseAsAccess(id: number) {
    return ACCESS_CASE_ID.includes(id);
  }

  scrollToTopList() {
    document.getElementById('Event-top').scrollIntoView({
      behavior: 'smooth',
    });
  }

  ruDec(val: number, strings: string[]) {
    return ruDeclension(val, strings);
  }
}
