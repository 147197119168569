
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ItemInfo from '@/components/Item/Item.vue';
import { Item as IItem } from '@/types/Item';
import { EventPlayer } from '@/requests/Event/getTop/getEventTop';
import Avatar from '@/components/Avatar.vue';

@Component({
  components: {Avatar, ItemInfo},
})
export default class TopPlace extends Vue {
  @Prop({required: false}) item: IItem;
  @Prop() place: number;
  @Prop({default: false}) me: boolean;
  @Prop() user: EventPlayer;
}
