
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
  @Prop() completed: boolean;
  @Prop() state: number[];

  get currentValue() {
    if (this.completed) {
      return this.state[1];
    }
    return this.state[0];
  }
}
