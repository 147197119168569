const ROLES_CATEGORY_ID = 6;

export const getCaseValuablePrice = (price: number, categoryId?: number) => {
  if (categoryId === ROLES_CATEGORY_ID) {
    return Math.floor(price * 1.55);
  }
  return Math.round(
    price < 200 ? Math.floor(price * 1.3) : Math.floor(price * 1.4)
  );
};
