import { Item } from '@/types/Item';

export const getItemImage = (
  item: Pick<Item, 'type' | 'image'>,
  size: { width: number; height: number } = { width: 108, height: 108 }
) => {
  return item.type !== 'item'
    ? item.image
    : `https://steamcommunity-a.akamaihd.net/economy/image/${item.image}/${
        size.width
      }fx${size.height}f`;
};
