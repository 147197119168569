
import { Component, Vue } from 'vue-property-decorator';

import Button from '@/components/Buttons/Button.vue';
import AuthButton from '@/components/Buttons/AuthButton.vue';
import LoginGeneralButton from '@/components/Buttons/LoginGeneralButton.vue';
import { getSeasonName } from '@/helpers/getSeasonName';

@Component({
  components: { Button, AuthButton, LoginGeneralButton },
})
export default class WelcomeEvent extends Vue {
  get season() {
    return this.$store.state.season;
  }

  get seasonName() {
    return getSeasonName(this.season);
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }
}
