
import { Component, Prop, Vue } from 'vue-property-decorator';
import VLazyImage from 'v-lazy-image/v2';

@Component({ components: { VLazyImage } })
export default class Avatar extends Vue {
  @Prop({ required: false }) readonly img: string;
  @Prop({ required: false }) readonly alt: string;

  get image() {
    const placeholder = require('@/assets/img/case-placeholder.svg');
    if (!this.img) return placeholder;

    return this.img;
  }
}
