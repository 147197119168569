var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('router-link',{class:{ 'case-info': true, '--free': !_vm._case.price || _vm._case.isFarmCase },attrs:{"to":'/case/' + _vm._case.id}},[_c('div',{staticClass:"case-info__img-wrap"},[_c('CaseImage',{staticClass:"case-info__img",attrs:{"src":_vm._case.image,"alt":_vm._case.name,"mana":_vm._case.isManaCase}})],1),_c('div',{staticClass:"case-info__label-wrap"},[_c('div',{staticClass:"case-info__label"},[(_vm._case.activations)?_c('span',{staticClass:"_activations"},[_c('span',{staticClass:"_current"},[_vm._v(_vm._s(_vm._case.activations.max - _vm._case.activations.current))]),_vm._v(" / "),_c('span',{staticClass:"_max"},[_vm._v(_vm._s(_vm._case.activations.max))]),_c('span',{staticClass:"_progress",style:({
            width:
              Math.round(
                ((_vm._case.activations.max - _vm._case.activations.current) /
                  _vm._case.activations.max) *
                  100
              ) + '%',
          })})]):_vm._e(),(!_vm._case.activations && !_vm._case.eventPoints)?_c('span',[_vm._v(_vm._s(_vm._case.label))]):_vm._e(),(!_vm._case.activations && _vm._case.eventPoints)?_c('span',{staticClass:"_runes"},[_c('span',[_vm._v(" +"+_vm._s(_vm._case.eventPoints)+" "),_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/tasks.svg')}})],1),_c('span',{staticClass:"_hover"},[_vm._v(" Попади в топ ")])]):_vm._e()])]),_c('div',{staticClass:"case-info__name"},[_vm._v(" "+_vm._s(_vm._case.name)+" ")]),_c('div',{staticClass:"case-info__param-wrap"},[_c('div',{staticClass:"case-info__param"},[(_vm._case.isFarmCase || _vm._case.isBonusCase)?_c('FreeCaseInfo',{attrs:{"isFarmCase":_vm._case.isFarmCase,"caseId":_vm._case.id}}):(_vm._case.isManaCase)?_c('div',{staticClass:"_mana"},[_c('InlineSvg',{attrs:{"src":require('@/assets/img/icons/mana.svg')}}),_vm._v(" Мана "),_c('span',[_vm._v(_vm._s(_vm._case.price))])],1):(_vm._case.price)?_c('div',{staticClass:"_money"},[(!_vm._case.isPremiumCase)?_c('span',{staticClass:"_old-price"},[_vm._v(_vm._s(_vm._case.oldPrice))]):_vm._e(),_c('span',{staticClass:"_active-price"},[_vm._v(" "+_vm._s(_vm._case.price)+" "),_c('span',{staticClass:"coin --middle"})])]):_vm._e(),(_vm._case.price)?_c('span',{staticClass:"_show"},[_vm._v(" Посмотреть ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }