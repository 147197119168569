
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface SwitcherOption {
  code: string;
  name: string;
}

@Component
export default class Switcher extends Vue {
  @Prop() items: SwitcherOption[];
  @Prop() active?: string;

  activeItem: string = '';

  mounted() {
    this.activeItem = this.active
      ? this.items.filter((item) => { return item.code == this.active; })[0].code
      : this.items[0].code;
  }

  changeActiveItem(code: string) {
    this.activeItem = code;
    this.$emit('onChange', this.activeItem);
  }
}
