
import { Component, Prop, Vue } from 'vue-property-decorator';
import VLazyImage from 'v-lazy-image/v2';

import { getItemImage } from '@/helpers';
import { Item } from '@/types/Item';

@Component({ components: { VLazyImage } })
export default class ItemImage extends Vue {
  @Prop() readonly item: Item;
  @Prop() noLazy: Boolean;

  get itemImage() {
    if (!this.item) return require('@/assets/img/item-placeholder.svg');
    return getItemImage(this.item, { width: 200, height: 132 });
  }
}
