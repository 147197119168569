
import { Component, Vue, Prop } from 'vue-property-decorator';
import Button from '@/components/Buttons/Button.vue';
import { UserAuthProvider } from '@/types/User';

@Component({ components: { Button } })
export default class AuthButton extends Vue {
  @Prop() readonly provider: UserAuthProvider;
  @Prop() readonly iconOnly?: Boolean;

  auth() {
    window.location.href = `/auth/${this.provider}`;
  }
}
