
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Case } from '@/types/Case';
import CaseImage from '@/components/Case/CaseImage.vue';
import CaseCountdown from '@/components/Index/CaseCard/CaseCountdown.vue';
import FreeCaseInfo from '@/components/Index/CaseCard/FreeCaseInfo.vue';

@Component({
  components: { FreeCaseInfo, CaseImage, CaseCountdown },
})
export default class CaseCard extends Vue {
  @Prop() _case: Case & {
    isManaCase: boolean;
    isPremiumCase: boolean;
    isFarmCase: boolean;
    isBonusCase: boolean;
    oldPrice: number;
  };
}
