var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticStyle:{"margin-top":"9px"}},[_c('h1',{staticStyle:{"overflow":"hidden","width":"1px","height":"1px"}},[_vm._v(" Дарим три арканы в Летнем приключении дота 2, Dota 2 приключение с халявными предметами! ")]),(_vm.isDevelopment)?_c('button',{staticStyle:{"position":"absolute","z-index":"10"},on:{"click":_vm.completeEvent}},[_vm._v("Завершить Событие")]):_vm._e(),(_vm.isTasksLoading)?_c('LoadingScreen'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.isTasksLoading)?_c('div',[(_vm.userCompletedEvent)?_c('CompletedEvent',{attrs:{"eventEndTime":_vm.eventEndTime}}):_c('div',{class:{ '--ended-top-part': _vm.userCompletedEvent }},[(_vm.user && _vm.eventStarted)?_c('div',{staticClass:"event-background",style:({
            ['--bg']:
              'url(' +
              require('@/assets/img/event/' + _vm.season + '/header.png') +
              ')',
            ['--bgMob']:
              'url(' +
              require('@/assets/img/event/' + _vm.season + '/header.png') +
              ')',
          })},[_c('img',{staticClass:"_left-art",attrs:{"src":require('@/assets/img/event/' + _vm.season + '/header-art-1.png')}}),_c('img',{staticClass:"_right-art",attrs:{"src":require('@/assets/img/event/' + _vm.season + '/header-art-2.png')}}),_c('div',{staticClass:"_fade"}),_c('img',{staticClass:"_mob-bg",attrs:{"src":require('@/assets/img/event/' + _vm.season + '/the-end-bg-mob.png')}})]):_c('NoAuth',{on:{"startEvent":_vm.startEvent}}),(_vm.user && _vm.eventStarted)?_c('div',{staticClass:"event-mob-head"},[_c('div',{staticClass:"_title"},[_c('div',{staticClass:"_row-1"},[_vm._v(_vm._s(_vm.seasonName))]),_c('div',{staticClass:"_row-2"},[_vm._v("Приключение")])]),_c('p',{staticClass:"_text"},[_vm._v(" Проходи простые задания"),_c('br'),_vm._v(" Получай бесплатные предметы"),_c('br'),_vm._v(" Наслаждайся призами ")])]):_vm._e(),(_vm.user && _vm.eventStarted)?_c('div',{staticClass:"wrapper event-timer-wrap"},[_c('div',{staticClass:"event-timer-desk-info"},[_c('div',{staticClass:"event-timer-desk-info__title"},[_c('div',{staticClass:"_row-1"},[_vm._v(_vm._s(_vm.seasonName))]),_c('div',{staticClass:"_row-2"},[_vm._v("ПРИКЛЮЧЕНИЕ")])]),_c('div',{staticClass:"event-timer-desk-info__text"},[_vm._v(" Проходи простые задания"),_c('br'),_vm._v(" Получай бесплатные предметы ")])]),(_vm.user && _vm.eventStarted)?_c('EventTimer',{attrs:{"eventEndTime":_vm.eventEndTime}}):_vm._e()],1):_vm._e(),(!_vm.userCompletedEvent)?_c('UserTasks',{attrs:{"fakeVersion":!_vm.user || !_vm.eventStarted}}):_vm._e()],1),_c('div',{class:{
          'promobanner-wrap wrapper': true,
          '--with-end': _vm.userCompletedEvent,
        },style:({ overflow: 'visible' })},[_c('EventBanner')],1),_c('div',{staticClass:"event-mob-divide"}),_c('div',{staticClass:"wrapper",attrs:{"id":"top"}},[_c('EventTop'),_c('CasesPreview',{staticClass:"farm-cases",attrs:{"categories":_vm.onlyFarmCaseCategories,"forEvent":true,"id":"farm"}}),_c('PromoBanner',{attrs:{"type":6}})],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }