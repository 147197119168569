import Axios from 'axios';

export default async function completeTask(
  id: number
): Promise<{ userMana: number }> {
  if (process.env.NODE_ENV !== 'development') {
    const { data } = await Axios.post<{ userMana: number }>(
      '/api/event/tasks-complete',
      { id }
    );

    return { ...data };
  }

  return { userMana: 200 };
}
